import MAL from "./namespace"
window.MAL = MAL
window.$ = window.jQuery = require("jquery")
window._ = require("lodash")
window.axios = require("axios")
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
window.axios.interceptors.request.use(config => {
  // カスタムヘッダーにリファラを格納。ログイン後のリダイレクト等に使用する
  config.headers["X-Referral-Path"] = location.href.replace(location.origin, "")
  return config
})

// PWA
require("../../common/pwa")

// CSRF対策
require("../../common/csrfProtection")

// botfilter（利用停止）
// require("../../common/botfilter")

// URLから内部パラメータをとりのぞく
require("../../common/removeInternalParams")

// adblock対策
require("./adblock")

// recaptcha
require("../../common/recaptcha")

import appendQuery from "append-query"

// Vue standalone build
window.sVue = require("vue/dist/vue.common.js")
window.sVue.mixin({
  delimiters: ["${", "}"],
  methods: {
    urlWithLocation(url, location) {
      return location ? appendQuery(url, {_location: location}) : url
    }
  },
})

// window.sVue.config.debug = true

// Vue Lazyload
import VueLazyLoad from "vue-lazyload"
window.sVue.use(VueLazyLoad)

import VueCookie from 'vue-cookie'
window.sVue.use(VueCookie)

require("../../common/jquery.exists")
require("../../common/jquery.url2embed")

require("./main")
require("./search")
require("./ad")
require("./tutorial")
require("./top_scroller")
require("./profilecard")

// Google Analytics
require("../../common/ga")
// URLを埋め込みコンテンツによしなに置き換える
import setupUrl2Embed from "../../common/url2embed"
setupUrl2Embed()

// lazyload
require("./lazyload")

// 広告リフレッシュのモジュール
require("../../common/ad-refresh.js")

// RightStuf
require("./right_stuf.js")

// swiper
require("./swiper")

// smart app banner
require("smartbanner.js")

// GTM event
require("../../common/gtm")

// smart ad server
require("../../common/smart-ad-server")

// EasyToAd
require("./jquery.touchswipe.js")
require("./easy2add.js")

//custom component
require("../../common/custom-components/mal-score-selector")