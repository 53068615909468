require("../common")
require("../pages/news/news")
require("../pages/ownlist/ownlist")// add-edit dialogue
require("../pages/anime/anime")
require("../pages/anime/season")
require("../pages/anime/video")
require("../pages/event/fifteenth_anniversary")
require("../pages/forum/forum")
require("../pages/manga/adapted")
require("../pages/people/people")
require("../pages/profile/friend")
require("../pages/membership/membership")
require("../pages/membership/paypal")
require("../pages/notification/notification")
require("../pages/profile/profile")
require("../pages/recommendation/recommendation")
require("../pages/rescue/rescue")
require("../pages/review/review")
require("../pages/ranking/ranking")
require("../pages/store/store")
require("../pages/work/misc")
require("../../common/pages/account/payment-settings")
require("../pages/poll/mhwc2023")
require("../pages/work/search")
require("../pages/stacks/stacks")
require("../../common/text_ribbon")
require("../../common/bbcode")
require("../../common/ui")
require("../../common/register_link")
require("../../common/jquery.scrollintoview")
require("../../pc/pages/poll/20th_anniversary.js")

// Vue
require("../../../vue/pages/ownlist")
require("../../../vue/pages/manga_store/viewer/sp/mount")
require("../../../vue/components/gdpr/sp/mount")
require("../../../vue/components/manga_store/sp/organisms/slider/mount")
require("../../../vue/components/manga_store/sp/organisms/search_box/mount")
require("../../../vue/components/manga_store/sp/organisms/purchase_single_button/mount")
require("../../../vue/components/manga_store/sp/organisms/purchase_bulk_button/mount")
require("../../../vue/components/mal_support/mount")
require("../../../vue/components/affiliate/amazon/sp-mount")
